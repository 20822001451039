import React from "react"
import Layout from "../components/layout"

import {
  ThemeProvider,
  Box,
  CssBaseline,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"

import theme from "../theme"
import SEO from "../components/seo"
import QuoteHero from "../components/quote-hero"
import GeneralSection from "../components/general-section"
import headingImageSrc from "../images/logo-heading.png"
import MainContent from "../components/main-content"
import CoButton from "../components/co-button"
import ProfileSection from "../components/profile-section"
import ContactSection from "../components/contact-section"
import DialogClosedBooking from "../components/dialog-closed-booking"

const AboutPage = () => {
  const theme = useTheme()
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"))
  const [open, setOpen] = React.useState(false)
  const data = {
    headline: "WHERE YOU MATTER",
    headlineMaxWidth: "592px",
    tagline:
      "Green Stone Wellness always have you in mind because you only have one body.",
    taglineMaxWidth: "370px",
    primaryBtnLabel: "BOOK ONLINE",
    primaryHref: theme.data.janeApp,
    index: false,
    heroVh: "70vh",
  }
  const avatarDescription = (
    <Box>
      <Typography>
        2017 graduate of Vancouver Career College, she is also a licensed
        massage therapist with the College of Massage Therapists of BC (CMTBC)
        and Registered Massage Therapists Association of BC (RMTBC). She also
        graduated Business Admin in Japan (1991).
      </Typography>
    </Box>
  )

  const onOpenDialog = () => {
    setOpen(true)
  }

  const onCloseDialog = () => {
    setOpen(false)
  }

  return (
    <Layout data={data} onOpenDialog={onOpenDialog}>
      <SEO
        title="About"
        description="Green Stone Wellness Clinic was established by Midori Ishimaru in 2019. The aim of the clinic is to have more flexible hours to treat patients. Aside from being flexible, Midori wanted the patients to use their extended health care from their insurance."
      />
      <GeneralSection mb="10rem">
        <QuoteHero
          srcDesktop="quote-desktop.jpg"
          alt="It is important to be conscientious, honest, and reliable in being consistently helpful to other people."
        />
      </GeneralSection>
      <GeneralSection mb="10rem">
        <MainContent
          headingImageSrc={headingImageSrc}
          headingImageAlt="Greenstone Wellness"
        >
          <Box>
            <Box textAlign="justify" my="2rem">
              <Typography paragraph={true}>
                Green Stone Wellness Clinic was established by Midori Ishimaru
                in 2019.
              </Typography>
              <Typography paragraph={true}>
                The aim of the clinic is to have more flexible hours to treat
                patients. Aside from being flexible, Midori wanted the patients
                to use their extended health care from their insurance.
              </Typography>
              <Typography>
                The very long and tedious ways of using their insurance have
                made patients loose interest in taking care of themselves all
                together. This is why the clinic has equipped and applied for
                direct billing to most major insurance companies. Having the 3rd
                party (the clinic) submit claims in behalf of the patients, the
                patients do not have to submit insurance claims and wait for
                reimbursements for a long time.
              </Typography>
            </Box>
            {!matchesSm && (
              <Box textAlign="center">
                <CoButton
                  type="primary"
                  href={theme.data.janeApp}
                  onClick={() => onOpenDialog()}
                >
                  BOOK NOW
                </CoButton>
              </Box>
            )}
          </Box>
        </MainContent>
      </GeneralSection>
      <GeneralSection mb="10rem">
        <QuoteHero
          srcDesktop="quote-desktop-about-2.jpg"
          alt="What I love most about my work as a registered massage therapist are the people's smile after their treatments."
        />
      </GeneralSection>
      <ProfileSection
        title="Midori Ishimaru"
        avatarDescription={avatarDescription}
        mb="10rem"
      >
        <MainContent title="Inspirations" mb="3rem">
          <Typography paragraph={true}>
            When Midori was young, she provided head and neck massage her older
            sister to get rid of her headache. Her sister appreciated it so
            much. Later on in life, Midori realized that so many people were
            suffering from chronic pain and they usually ignore those
            discomfort.
          </Typography>
          <Typography>
            Midori’s friends say that she has a knack for making people feel
            relaxed and welcome. She wants people to experience the life without
            pain or even discomfort, and if possible less than what their
            current situation is.
          </Typography>
        </MainContent>
        <MainContent title="Aspirations" mb="3rem">
          <Typography paragraph={true}>
            Midori wants to be conscientious, wishing to do what is right. She
            wants to be honest, not only telling the truth but also living it.
            Also she wants to always be reliable, to be consistently good in her
            quality service.
          </Typography>
          <Typography>
            She wants her actions to follow what she says so people can trust
            her and be comfortable around her. Midori believes that we never
            feel other people's pain that is why she wants to be compassionate
            to others and understand their situations. Ultimately, Midori
            believes that everybody just wants to be understood by someone.
          </Typography>
        </MainContent>
        <MainContent title="On Her Free Time">
          <Typography paragraph={true}>
            Midori likes cooking, entertaining her friends and family, hiking to
            see canada's lakes and mountains, having wine and snacks on the
            beach, reading novels, learning pottery, and gardening in summer.
          </Typography>
          <Typography paragraph={true}>
            She would love to visit in Okinawa, Japan because it’s warm, it has
            some laid back characters, and has lovely scenery.
          </Typography>
          {!matchesSm && (
            <Box textAlign="center">
              <CoButton
                href={theme.data.janeApp}
                type="primary"
                onClick={() => setOpen(true)}
              >
                BOOK ONLINE
              </CoButton>
            </Box>
          )}
        </MainContent>
      </ProfileSection>
      <ContactSection onOpenDialog={onOpenDialog} />
      <DialogClosedBooking open={open} onCloseDialog={onCloseDialog} />
    </Layout>
  )
}

const StyledAboutPage = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AboutPage />
  </ThemeProvider>
)

export default StyledAboutPage
