import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  container: {
    background: props =>
      props.backgroundImage &&
      `url(${props.backgroundImage}) center / cover no-repeat`,
  },
})

const GeneralSection = ({
  backgroundImage,
  mb = "10rem",
  py,
  children,
  ...rest
}) => {
  const classes = useStyles({ backgroundImage })
  return (
    <Box className={classes.container} mb={mb} py={py} {...rest}>
      {children}
    </Box>
  )
}

export default GeneralSection
