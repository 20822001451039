import React from "react"

import { Box } from "@material-ui/core"

import Image from "../components/image"

const QuoteHero = props => {
  const { srcDesktop, alt } = props

  return (
    <Box>
      <Image imgName={srcDesktop} alt={alt} />
    </Box>
  )
}

export default QuoteHero
